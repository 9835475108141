/* Common styles */
.root {
    background: var(--light-bg-color);
    padding: 20px 0 0;
    border-radius: 20px;
}

.container {
    color: var(--dark-color);
}

.title {
    font-size: 28px;
    color: var(--dark-color);
    font-weight: 700;
    line-height: 36px;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #bbbbbb;
    margin: 10px 0 15px;
}

/* BlockDetails styles */
.details-container {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    word-break: break-all;
    margin-bottom: 16px;

    span {
        font-weight: 700;
        color: var(--primary-color);
    }

    a {
        color: var(--dark-color); /* Set your desired color for the links */
        text-decoration: underline; /* You can customize the text-decoration as needed */
        font-weight: 700;
    }
}

.not-found {
    /* Add styles for the "Notfound" message if needed */
}

/* Table styles */
.table-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;
    
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}
