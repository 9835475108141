.root {
    position: relative;
    min-height: 100vh;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    filter: blur(15px);
    
    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}

.content-container {
    width: 95%;
    min-height: 800px;
    margin: 0 auto;
    display: flex;
    gap: 18px;
    padding-bottom: 30px;
}

.content {
    position: relative;
    width: calc(100% - 450px - 18px);
    display: flex;
    gap: 40px;
    background-color: var(--secondary-bg-color);
    border-radius: 5px;
    padding: 20px 40px 70px;

    &--collapse {
        width: calc(100% - 100px - 18px);
    }
}