.root {
    // padding: 30px 0;
    background-color: var(--secondary-bg-color);
}

.container {
    width: 95%;
    margin: 0 auto;
    padding: 20px 40px;
    background-color: var(--secondary-bg-color);
    border-radius: 15px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.logo {
    max-height: 100%;
    width: auto;

    img {
        width: 50px;
        height: auto;
    }
}

.title {
    font-family: var(--primary-font);
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
}

.description {
    font-family: var(--primary-font);
    font-size: 18px;
    line-height: 28px;
}

.sidebar-item {
    padding: 20px 10px;
    font-size: 20px;
    color: var(--secondary-color);

    &--active {
        color: var(--primary-color);
    }
}
