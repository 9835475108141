.root {
    // padding: 30px 0;
    width: 450px;
    max-width: 35%;
    background-color: var(--secondary-bg-color);
    padding: 40px 0 70px;
    border-radius: 5px;
    position: relative;

    &--collapse {
        width: 100px;
    }
}

.container {
    background-color: var(--secondary-bg-color);
    border-radius: 15px;
}

.logo {
    max-height: 100%;
    width: auto;

    img {
        width: 50px;
        height: auto;
    }
}

.title {
    font-family: var(--primary-font);
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;

    padding: 0 40px 40px;
    border-bottom: 1px solid var(--secondary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.description {
    font-family: var(--primary-font);
    font-size: 18px;
    line-height: 28px;
}

.sidebar-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 0 40px;
    margin-top: 20px;
}

.sidebar-item {
    padding: 10px 0;
    font-size: 20px;
    color: var(--secondary-color);
    position: relative;
    display: block;

    &:not(:first-child) {
        &::after {
            content: "";
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
            height: 1px;
            background: var(--secondary-color);
            opacity: 0.3;
        }
    }

    &--active {
        color: var(--primary-color);

        svg {
            fill: var(--primary-color);
        }
    }

    span {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.close {
    width: 30px;
    height: 30px;
    cursor: pointer;
}