:root {
    //color
    --primary-color: #0969ff;
    --secondary-color: #000000;
    --dark-color: #000000;
    --link-color: navy;

    --primary-bg-color: #F3F3F7;
    --secondary-bg-color: #FEFEFE;
    --light-bg-color: #ffffff;
    
    --signed-block-color: #13deb9;
    --missed-block-color: #fa896b;

    //font
    --primary-font: "Tilt Neon", sans-serif;
}