.root {
    position: relative;
  }
  
  .container {}
  
  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }
  
  .line {
    width: 100%;
    height: 2px;
    background-color: #bbbbbb;
    margin: 10px 0 30px;
  }
  
  .block-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  /* Add a class for the JSON data box */
  .box {
    background-color: #282c34;
    color: #abb2bf;
    padding: 20px;
    border-radius: 5px;
    overflow: auto;
    max-height: 500px; /* Set a max height, adjust as needed */
  }
  
  .box pre {
    margin: 0;
    white-space: pre-wrap;
  }
  
  /* Add styles for the copy button */
  .copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    background-color: #61dafb;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
  
  .copy-button:hover {
    background-color: #4fa3d1;
  }
  