.root {
    background: var(--light-bg-color);
    padding: 20px 0 0;
    border-radius: 20px;
}

.container {
    color: var(--dark-color);
}

.title {
    font-size: 28px;
    color: var(--dark-color);
    font-weight: 700;
    line-height: 36px;
}

.content {
    font-size: 20px;
    color: var(--dark-color);
    font-weight: 400;
    line-height: 26px;
    word-break: break-all;
    margin-bottom: 16px;

    span {
        font-weight: 700;
        color: var(--primary-color);
    }
}

.line {
    width: 100%;
    height: 2px;
    background-color: #bbbbbb;
    margin: 10px 0 15px;
}